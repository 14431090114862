.loader_data{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(181 174 174 / 81%);
    z-index: 9999;
}
.loader{
    
    left: 45%;
    top: 45%;
    z-index: 1000;
    position: absolute;
    transform: scale(0.5);
    
}
.screenLoader {
    position: fixed;
    height: 100%;
    background: #00000047;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40vh;
  }